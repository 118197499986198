<template>
  <div class="open-new">
    <new-question></new-question>
  </div>
</template>

<script>
import NewQuestion from '@/components/NewQuestion'

export default {
  name: 'adminOpenNew',
  components: {
    NewQuestion
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {
  }
}
</script>

<style scoped></style>
<style lang="scss" scoped>
.open-new {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
</style>
